<template>
	<div class="border shadow-lg w-1/2 rounded-lg p-12 relative inset-0 m-auto mt-24">
		<img src="@/assets/images/as-logo.svg" class="w-48 mb-6">
		<h1 class="text-2xl font-light text-gray-700 mb-10">
			<span v-if="!ui.registering">Sign in</span><span v-else>Sign up</span>, maintain networks</h1>
		<div class="grid grid-cols-12 flex items-center">
			<div class="col-span-7 py-4">
				<div class="grid grid-cols-12 gap-4" v-if="ui.registering"> 
					<div class="col-span-6">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="name">
							Name
						</label>
						<input class="shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-400 mb-5" type="text" placeholder="Jane" name="name" v-model="ui.name"> 	
					</div>
					<div class="col-span-6">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="lastname">
							Lastname
						</label>
						<input class="shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-400 mb-5" type="text" placeholder="Doe" name="lastname" autocomplete v-model="ui.lastname">
					</div>
				</div>
				<label class="block text-gray-700 text-sm font-bold mb-2" for="email">
					Email
				</label>
				<input class="shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-400 mb-5" type="email" placeholder="jane@doe.com" name="email" v-model="ui.email">

				<label class="block text-gray-700 text-sm font-bold mb-2" for="password">
					Password
				</label>
				<input class="shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" type="password" name="password" v-model="ui.password" @keyup.enter="login">

				<label v-if="ui.registering" class="block text-gray-700 text-sm font-bold mb-2 mt-4" for="confirmpassword">
					Confirm Password
				</label>
				<input v-if="ui.registering" class="shadow-inner appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" type="password" name="confirmpassword" autocomplete v-model="ui.password_confirmation">

				<label v-if="!ui.registering" class="mt-4 block">
					<input type="checkbox" class="align-text-top" name="remember">
					Remember me
				</label>
				<div class="mt-5">
					<button v-if="!ui.registering" type="button" class="px-5 py-2 mt-4" @click="ui.registering = true">Create a new account</button>
					<button v-if="ui.registering" type="button" class="px-5 py-2 mt-4" @click="ui.registering = false">Sign in, instead</button>
					
					<button type="button" @click="login" v-if="!ui.registering" class="bg-primary text-white rounded px-5 py-2 mt-4 float-right font-light">Sign in</button>
					<button v-if="ui.registering" type="button" @click="register" class="bg-primary text-white rounded px-5 py-2 mt-4 float-right font-light">Create Account</button>

				</div>
			</div>
			<div class="col-span-5 py-4 -mt-10">
				<img src="//cdn.albismart.cloud/iso-desk.png">
				<p class="px-8 text-gray-700"><span v-if="!ui.registering">Sign in to your</span><span v-else>Create new</span> account and manage networks.</p>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	export default {
		name: "Login",
		data() {
			return {
				ui: {
					name: '',
					lastname: '',
					email: '',
					password: '',
					password_confirmation: '',
					registering: false
				}
			}
		},
		methods: {
			login() {
			  this.$store.dispatch('retrieveToken', {
			    email: this.ui.email,
			    password: this.ui.password
			  })
			    .then((response) => {
			    	if(response.data) {
				      this.$router.push('/home')
				  }
			    })
			},
			register() {
				axios.post('https://albismart.cloud/register', {
					name: this.ui.name,
					lastname: this.ui.lastname,
					email: this.ui.email,
					password: this.ui.password,
					password_confirmation: this.ui.password_confirmation
				})
				.then((response) => {
					if(response.status==200) {
						alert("Registered successfully!")
						this.$router.go();
					}
				})
			}
		}
	}
</script>