<template>
	<div class="grid grid-cols-12 gap-8 py-16">
		<div class="col-span-12">
			<toggle-view :title="'Profile'" :subtitle="'See the data in your AlbiSmart Account and choose what activity is saved to personalize your AlbiSmart experience'" :buttonName="'Manage your Profile'" :image="require('@/assets/images/1.png')">
				<div class="grid grid-cols-12">
					<div class="col-span-3 text-gray-600 border-r">
						<button class="block focus:outline-none" v-for="(tab, index) in ui.tabs" :key="index" @click="focus = tab.title">
							<i class="px-2 py-4" :class="[tab.icon, {'text-primary': focus==tab.title}]"></i>
							<span :class="{'text-gray-800 font-medium': focus==tab.title}">{{ tab.title }}</span>
						</button>
					</div>
					<div class="col-span-9">
						<!-- Profile -->
						<div class="px-8 py-2" v-if="focus=='Profile'">
							<h1 class="text-lg text-gray-700 mb-6">General Info</h1>
							<div class="grid grid-cols-12 mb-10">
								<div class="col-span-2 relative">
									<img src="@/assets/images/avatar.jpg" class="w-20 h-20 border-4 rounded-lg inline-block absolute top-0 z-10" @mouseover="uploadImage = true" v-if="!uploadImage">
									<!-- Upload image -->

									<label class="w-20 h-20 flex flex-col items-center py-8 bg-gray-100 text-gray-600 rounded-lg shadow-lg tracking-wide uppercase cursor-pointer border absolute top-0 opacity-75" @mouseout="uploadImage = false" v-if="uploadImage">
										<i class="fas fa-camera fa-lg align-middle absolute top-1/2"></i>
										<input type='file' class="hidden" />
									</label>

									<!-- End - Upload image -->
								</div>
								<div class="col-span-10">
									<p class="text-2xl text-gray-700">{{ userData.name }} <span v-if="userData.middlename">{{ userData.middlename }}</span> {{ userData.lastname }}</p>
									<p class="text-gray-600">Customize your profile settings.</p>
								</div>
							</div>
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>First Name</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" type="text" placeholder="Name" v-model="userData.name">
								</div>
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>Last Name</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" type="text" placeholder="Last name" v-model="userData.lastname">
								</div>
							</div>
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="email">
										<small>Email</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none placeholder-gray-300" id="email" type="email" placeholder="john@doe.com" v-model="userData.email">
								</div>
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>Username</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none placeholder-gray-300 bg-gray-300" type="text" placeholder="johndoe" v-model="userData.username" disabled>
								</div>
							</div>
							<button class="transition duration-300 ease-in-out bg-primary active:bg-secondary focus:outline-none text-white font-bold py-2 px-4 rounded" type="button" @click="updateProfile">
								Save changes
							</button>
						</div>

						<!-- End - Profile -->
					</div>
				</div>
			</toggle-view>
		</div>
	</div>
</template>
<script>
	import ToggleView from '../resources/components/ToggleView.vue'
	import axios from 'axios'
	export default {
		name: 'PersonalInfo',
		components: { ToggleView },
		data() {
			return {
				userData: {},
				uploadImage: false,
				focus: 'Profile',
				ui: {
					tabs: [
					{ title: 'Profile', icon: 'far fa-id-card' }
					]
				}
			}
		},
		mounted() {
			this.retrieveUser();
		},
		methods: {
			retrieveUser() {
				let vm = this;
				axios.get("https://albismart.cloud/user", 
					{ headers: { "Accept": "application/json", "Authorization": "Bearer "+this.$store.state.token} 
				})
				  .then(function (response) {
				    // handle success
				    // console.log(response);
				    if(response.status==200) {
					    vm.userData = response.data;
				    }
				  })
				  .catch(function (error) {
				    // handle error
				    console.log(error);
				  });
			},	
			updateProfile() {
				let vm = this;
				axios.post('https://albismart.cloud/user', {
				        name: vm.userData.name,
				        lastname: vm.userData.lastname,
				        email: vm.userData.email,
				        _method: "patch"
					    },
				        { headers: { "Accept": "application/json", "Authorization": "Bearer "+this.$store.state.token}
				    })
				    .then((response) => {
				           console.log(response);
				    });
			}
		}
	}
</script>
<style scoped>
</style>