<template>
	<div>
		<!-- Nabar -->
		<navbar class="sticky top-0 z-20"></navbar>

		<!-- Sidebar and Content -->
		<div class="grid grid-cols-12">

			<div class="col-start-3 col-end-11">
				<transition name="fade">

					<router-view></router-view>

				</transition>
			</div>

		</div>
	</div>
</template>
<script>
	import Navbar from '../canvas/navbar/Navbar.vue'
	export default {
		components: { Navbar },
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {

		}
	}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-leave-active {
	transition-delay: .02s;
	transition-duration: .12s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

</style>