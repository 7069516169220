import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import './assets/css/tailwind.css'
import routes from './routes.js'
import { store } from './vuex/auth/session.js'
Vue.config.devtools = true;

Vue.use(VueRouter);
window.axios = require('axios');

Vue.config.productionTip = false



let router = new VueRouter ({
	routes,
	linkActiveClass: 'is-active'
});


// Nav Guards
router.beforeEach((to, from, next) => {
	// Check for requiredAuth guard
	if(to.matched.some(record => record.meta.requiresAuth)) {
		// Check if NOT logged in
		if(!store.getters.loggedIn) {
			// Go to login
			next({
				path: '/login'
			});
		} else {
			// Proceed to route
			next();
		}
	} else if(to.matched.some(record => record.meta.requiresGuest)) {
		// Check if logged in
		if(store.getters.loggedIn) {
			// Go to home
			next({
				path: '/home'
			});
		} else {
			// Proceed to route
			next();
		}
	} else {
		// Proceed to route
		next();
	}
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
