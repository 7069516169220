<template>
	<div id="app">
		<transition name="fade">
			<login v-if="$route.path=='/login'"></login>
		</transition>
		<ui-canvas v-if="$route.path!='/login'"></ui-canvas>
	</div>
</template>

<script>
	import UiCanvas from '../src/resources/views/canvas/Canvas.vue';
	import Login from '../src/pages/login/Login.vue';
	export default {
		name: 'app',
		components: { Login, UiCanvas },
		data() {
			return {

			}
		}
	}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
