<template>
	<div>
		<div class="h-48 p-8 border border-borderCard rounded-t-lg">
			<span class="text-gray text-xl block" v-html="title"></span>
			<span class="text-gray-500">
				<span class="w-64 inline-block" v-html="subtitle"></span>
				<span class="float-right"><img :src="image"></span>
			</span>
		</div>
		<div class="border-t-0 border border-borderCard rounded-b-lg">
			<div class="border-borderCard px-8 py-4 hover:bg-gray-100 cursor-pointer" :class="{'rounded-b-lg': !toggle}" @click="toggle = !toggle" v-html="buttonName">
				<i v-if="toggle" class="transition duration-500 ease-in-out fas fa-edit bg-gray-300 text-gray-500 p-2 ml-2 -mt-1 rounded-lg opacity-75"></i>
			</div>
			<transition name="accordion-item"
			@enter="startTransition"
			@after-enter="endTransition"
			@before-leave="startTransition"
			@after-leave="endTransition">
			<div class="accordion-item-details" v-if="toggle">
				<hr class="py-4 border-borderCard">
				<div class="px-8 py-4">
					<slot></slot>
				</div>
			</div>
		</transition>
	</div>
</div>
</template>
<script>
	export default {
		props: {
			title: String,
			subtitle: String,
			image: String,
			buttonName: String
		},
		data() {
			return {
				toggle: false
			}
		},
		methods: {
			startTransition(el) {
				el.style.height = el.scrollHeight + 'px'
			},

			endTransition(el) {
				el.style.height = ''
			}
		},
		computed: {

		}
	}
</script>
<style scoped>

/* Slide down animation */
.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}

</style>