<template>
	<div>
		<!-- Nav -->
		<div class="shadow" :class="{ 'shadow': !view.atTopOfPage }">
			<nav class="flex items-center justify-between bg-white flex-wrap h-16 px-6">
				<router-link class="flex items-center flex-shrink-0 text-white mr-6 cursor-pointer border-none" tag="div" :to="'/'">
					<img src="@/assets/images/as-logo.svg" class="fill-current w-32 mr-2">
				</router-link>
				<div class="block lg:hidden">
					<button class="flex items-center px-3 py-2 border rounded border-teal-400 hover:text-white hover:border-white">
						<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
					</button>
				</div>
				<div class="w-full block flex-grow lg:flex lg:items-center md:items-center sm:items-center lg:w-auto">
					<div class="text-sm lg:flex-grow w-auto text-center">
						<div>
							<router-link v-for="(route, index) in routes" :key="index" class="inline-block transition duration-500 ease-in-out items-center px-8 py-5 font-medium text-gray-600 hover:text-primary" :to="route.path">
								<span class="">{{ route.name }}</span>
							</router-link>
						</div>
					</div>
					<div class="mr-4">
						<button @click="isOpenEnv = !isOpenEnv" class="bg-transparent hover:bg-gray-200 w-10 h-10 text-white font-bold py-2 px-4 rounded-full focus:outline-none"  :class="{'bg-gray-200': isOpenEnv}">
							<i class="far fa-question-circle fa-lg text-gray-500" style="margin-top: 6px;"></i>
						</button>
						<button v-if="isOpenEnv" tabindex="-1" @click="isOpenEnv = false" class="fixed inset-0 h-full w-full"></button>
						<div v-if="isOpenEnv" class="absolute right-0 mt-2 py-2 px-0 w-56 bg-white rounded-lg p-4 shadow-md">
							<a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-300 border-none">Help</a>
							<a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-300">Send Feedback</a>
						</div>
					</div>
					<div class="relative">
						<button @click="isOpen = !isOpen" class="transition duration-500 ease-in-out block h-10 w-10 rounded-full overflow-hidden border-2 border-white focus:outline-none focus:border-gray-500">
							<img src="@/assets/images/avatar.jpg" class="h-10 w-10 max-w-none rounded-full">
						</button>
						<button v-if="isOpen" tabindex="-1" @click="isOpen = false" class="fixed inset-0 h-full w-full"></button>
						<div v-if="isOpen" class="absolute right-0 mt-2 py-2 px-0 w-48 bg-white rounded-lg p-4 shadow-md">
							<a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-300">Account Settings</a>
							<a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-300">Support</a>
							<a href="#" class="block px-4 py-2 text-gray-800 hover:bg-gray-300" @click="logout">Log out</a>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<!-- End - Nav -->
	</div>
</template>
<script>
	export default {
		data () {
			return {
				isOpen: false,
				isOpenEnv: false,
				view: {
					atTopOfPage: true
				},
				activeClass: 'active',
				routes: [
				{
					path: '/home',
					name: 'Home',
					icon: 'fas fa-user-circle fa-lg'
				},
				{
					path: '/profile',
					name: 'Profile',
					icon: 'far fa-id-card fa-lg'
				},
				{
					path: '/privacy',
					name: 'Privacy',
					icon: 'fas fa-toggle-on fa-lg'
				},
				{
					path: '/security',
					name: 'Security',
					icon: 'fas fa-lock fa-lg'
				},
				{
					path: '/payment-subscriptions',
					name: 'Payment & subscriptions',
					icon: 'far fa-credit-card fa-lg'
				}
				]
			}
		},
		created() {
			const handleEscape = (e) => {
				if(e.key === 'Esc' || e.key === 'Escape') {
					this.isOpen = false;
				}
			}
			document.addEventListener('keydown', handleEscape)

			this.$once('hook:beforeDestroy', () => {
				document.removeEventListener('keydown', handleEscape)
			})
		},
		beforeMount () {
			window.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			handleScroll(){
				if(window.pageYOffset>0){
					if(this.view.atTopOfPage) this.view.atTopOfPage = false
				}else{
					if(!this.view.atTopOfPage) this.view.atTopOfPage = true
				}
			},
			logout() {
				this.$store.dispatch('destroyToken')
					.then(response => {
						// console.log(response);
					})
				setTimeout(() => {
					this.$router.go();
				}, 200);
			}
		}
	}
</script>
<style scoped>
.is-active { border-bottom-width: 1px; border-color: #662D91; color: #662D91; margin-top: 1px; }
</style>