import Login from './pages/login/Login.vue';
import Home from './pages/environment/Home.vue';
import Profile from './pages/Profile.vue';
import Privacy from './pages/Privacy.vue';
import Security from './pages/Security.vue';
import PaymentSubscriptions from './pages/Payment-Subscriptions.vue';

const routes = [
	{
		path: '*',
		redirect: '/home',
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/login',
		component: Login,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: '/profile',
		component: Profile,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/privacy',
		component: Privacy,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/security',
		component: Security,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/payment-subscriptions',
		component: PaymentSubscriptions,
		meta: {
			requiresAuth: true
		}
	}
];

export default routes