<template>
	<div class="grid grid-cols-12 gap-8 py-16">
		<div class="col-span-12">
			<toggle-view :title="'Payment & subscriptions'" :subtitle="'See the data in your AlbiSmart Account and choose what activity is saved to personalize your AlbiSmart experience'" :buttonName="'Manage Payment & Subscriptions'" :image="require('@/assets/images/4.png')">
				<div class="grid grid-cols-12">
					<div class="col-span-4 text-gray-600 border-r">
						<button class="block focus:outline-none" v-for="(tab, index) in ui.tabs" :key="index" @click="focus = tab.title">
							<i class="px-2 py-4" :class="[tab.icon, {'text-primary': focus==tab.title}]"></i>
							<span :class="{'text-gray-800 font-medium': focus==tab.title}">{{ tab.title }}</span>
						</button>
					</div>
					<div class="col-span-8">
						<!-- Account -->
						<div class="px-8 py-2" v-if="focus=='Profile'">
							<h1 class="text-lg text-gray-700 mb-6">General Info</h1>
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>First Name</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" id="username" type="text" placeholder="Name" v-model="ui.account.name">
								</div>
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>Last Name</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" id="username" type="text" placeholder="Last name" v-model="ui.account.lastName">
								</div>
							</div>
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="email">
										<small>Email</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none placeholder-gray-300" id="email" type="email" placeholder="john@doe.com" v-model="ui.account.email">
								</div>
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="username">
										<small>Username</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none placeholder-gray-300" id="username" type="text" placeholder="johndoe" v-model="ui.account.userName">
								</div>
							</div>
							<button class="transition duration-300 ease-in-out bg-primary active:bg-secondary focus:outline-none text-white font-bold py-2 px-4 rounded">
								Save changes
							</button>
						</div>

						<!-- End - Account -->
					</div>
				</div>
			</toggle-view>
		</div>
	</div>
</template>
<script>
	import ToggleView from '../resources/components/ToggleView.vue'
	export default {
		name: 'PersonalInfo',
		components: { ToggleView },
		data() {
			return {
				focus: 'Account',
				ui: {
					tabs: [
					{ title: 'Invoices', icon: 'fas fa-file-invoice' },
					{ title: 'Payment Methods', icon: 'fas fa-cash-register' }
					],
					account: {
						name: 'John',
						lastName: 'Doe',
						email: 'john@doe.com',
						userName: 'johndoe'
					}
				}
			}
		},
		methods: {

		}
	}
</script>