<template>
	<div class="grid grid-cols-12 gap-8 py-16">
		<div class="col-span-12">
			<toggle-view :title="'Security'" :subtitle="'See the data in your AlbiSmart Account and choose what activity is saved to personalize your AlbiSmart experience'" :buttonName="'Manage your Security settings'" :image="require('@/assets/images/3.png')">
				<div class="grid grid-cols-12">
					<div class="col-span-4 text-gray-600 border-r">
						<button type="button" class="block focus:outline-none" v-for="(tab, index) in ui.tabs" :key="index" @click="focus = tab.title">
							<i class="px-2 py-4" :class="[tab.icon, {'text-primary': focus==tab.title}]"></i>
							<span :class="{'text-gray-800 font-medium': focus==tab.title}">{{ tab.title }}</span>
						</button>
					</div>
					<div class="col-span-8">
						<!-- Password -->
						<div class="px-8 py-2" v-if="focus=='Password'">
							<h1 class="text-lg text-gray-700 mb-6">Change password</h1>
							<!-- Old password -->
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-8">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="oldpass">
										<small>Old Password</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" id="oldpass" type="password" placeholder="Old Password" v-model="updatePassword.oldPassword">
								</div>
							</div>
							<!-- End - Old password -->
							<!-- New password -->
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="newpass">
										<small>New Password</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" id="newpass" type="password" placeholder="New Password" v-model="updatePassword.newPassword">
								</div>
							</div>
							<!-- End - New password -->
							<!-- Password Confirmation -->
							<div class="grid grid-cols-12 gap-4">
								<div class="mb-4 col-span-6">
									<label class="block text-gray-500 text-sm font-bold mb-2 uppercase" for="passconfirmation">
										<small>Confirm password</small>
									</label>
									<input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder-gray-300" id="passconfirmation" type="password" placeholder="Confirm password" v-model="updatePassword.confirmPassword">
								</div>
							</div>
							<!-- End - Password Confirmation -->
							<button type="button" class="transition duration-300 ease-in-out bg-primary active:bg-secondary focus:outline-none text-white font-bold py-2 px-4 mt-4 rounded" @click="updatePass">
								Save changes
							</button>
						</div>
						<!-- End - Password -->
						<!-- Two factor authentication -->
						<div class="px-8 py-2" v-if="focus=='Two Factor Authentication'">
							<h1 class="text-lg text-gray-700 mb-6">Login verification</h1>

							<p class="text-gray-600 mb-4 text-sm">Login verification increases the protection of your AlbiSmart Account.</p>

							<p class="text-gray-600 mb-4 text-sm">We'll send you a code to your e-mail when accessing AlbiSmart account from an unrecognized device.</p>


							<div class="p-4">
								<modal :modalTitle="'Verify your identity'" :modalContent="'Your verification code will be sent to: '+maskEmail+'. Please proceed if this is how you wish to receive your verification code.'" :actionButton="'Send Security Code'" :closeButton="'Cancel'"></modal>
							</div>

						</div>
						<!-- End - Two factor authentication -->
					</div>
				</div>
			</toggle-view>
		</div>
	</div>
</template>
<script>
	import ToggleView from '../resources/components/ToggleView.vue'
	import Modal from '../resources/components/Modal.vue'
	import axios from 'axios'
	export default {
		name: 'PersonalInfo',
		components: { ToggleView, Modal },
		data() {
			return {
				activeModal: false,
				focus: 'Password',
				ui: {
					tabs: [
					{ title: 'Password', icon: 'fas fa-lock' },
					{ title: 'Two Factor Authentication', icon: 'fas fa-shield-alt' }
					],
					account: {
						name: 'John',
						lastName: 'Doe',
						email: 'john@doe.com',
						userName: 'johndoe'
					}
				},
				userData: null,
				updatePassword: {
					oldPassword: '',
					newPassword: '',
					confirmPassword: ''
				}
			}
		},
		mounted() {
			this.retrieveUser();
		},
		computed: {
			maskEmail() {
				let str = this.userData.email;
				str = str.split('');
				let finalArr=[];
				let len = str.indexOf('@');
				str.forEach((item,pos)=>{
					(pos>=1 && pos<=len-2) ? finalArr.push('*') : finalArr.push(str[pos]);
				})
				return finalArr.join('');
			}
		},
		methods: {
			retrieveUser() {
				let vm = this;
				axios.get("https://albismart.cloud/user", 
					{ headers: { "Accept": "application/json", "Authorization": "Bearer "+this.$store.state.token} 
				})
				  .then(function (response) {
				    // handle success
				    // console.log(response);
				    if(response.status==200) {
					    vm.userData = response.data;
				    }
				  })
				  .catch(function (error) {
				    // handle error
				    console.log(error);
				  });
			},	
			updatePass() {
				axios.post('https://albismart.cloud/user', {
				        password_old: this.updatePassword.oldPassword,
				        password: this.updatePassword.newPassword,
				        password_confirmation: this.updatePassword.confirmPassword,
				        _method: "patch"
				    	},
			        	{ headers: { "Accept": "application/json", "Authorization": "Bearer "+this.$store.state.token}
				    })
				    .then((response) => {
				    	// notifaction success
				    })
				    .catch(function (error) {
				      // handle error
				      if(error.response.status===422) {
				      	alert(error.response.data.errors);
				      }
				    });
			}
		}
	}
</script>