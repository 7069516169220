<template>
	<div class="mt-12 py-10">
		<div class="grid grid-cols-12 gap-4" :class="{'opacity-0': switchEnvironment}">

			<!-- Profile Info -->
			<div class="col-span-9 border rounded-lg p-10">
				<div class="grid grid-cols-12">
					<div class="col-span-2">
						<img src="@/assets/images/avatar.jpg" class="w-24 h-24 border-4 rounded-lg inline-block align-middle">
					</div>
					<div class="col-span-10">
						<p class="text-3xl text-gray-700" v-if="userData && userData.name">{{ userData.name }} <span v-if="userData.middlename">{{ userData.middlename }}</span> {{ userData.lastname}}</p>
						<p class="text-gray-600">Switch between your environments.</p>
					</div>
				</div>
			</div>
			<!-- End - Profile Info -->

			<!-- Home environment -->
			<div class="col-span-3 border text-center rounded-lg relative">
				<span class="absolute top-0 left-0">
					<i class="fas fa-home m-3 p-2 text-gray-500 border-2 rounded-lg bg-gray-300 opacity-50 inline-block"></i>
					<p class="inline-block text-gray-500 -ml-2">Home</p>
				</span>
				<div class="p-8 mt-4 relative">
					<img :src="activeEnv.logo" class="w-20 h-20 m-auto">
					<div v-if="editingEnvName" class="">
						<input type="text" v-model="activeEnv.name" class="border-2 border-gray-300 focus:outline-none rounded-full h-6 mt-2 w-32 absolute inset-x-0 m-auto text-center" @keyup.enter="updateEnvName" ref="input">
					</div>
					<div v-if="!editingEnvName" class="text-gray-600 relative" @mouseover="editOn = true" @mouseout="editOn = false" @click="editEnvName">
						<input type="Text" v-model="activeEnv.name" class="transition duration-300 ease-in-out absolute border-2 border-gray-300 bg-gray-300 rounded-full h-6 mt-2 text-center inset-x-0 m-auto" disabled :class="{'opacity-50': editOn}">
						<i class="fas fa-edit absolute right-0 mt-3" v-if="editOn"></i>
					</div>
				</div>
			</div>
			<!-- End - Home environment -->

			<!-- Cloud environments -->
			<div class="col-span-12 border rounded p-2 border-gray-200 bg-gray-100 w-full">
				<span class="inline-block flex items-center">
					<i class="fas fa-cloud fa-lg text-gray-500 inline-block"></i>
					<p class="inline-block text-1xl ml-2 text-gray-500">Cloud environments</p>
				</span>
			</div>

			<div class="col-span-12">
				<div class="grid grid-cols-12">
					<div class="col-span-12 border bg-gray-100 my-3 rounded-lg inline-block" v-for="(env, e) in environments" :key="e">

						<div class="grid grid-cols-12">
							<div class="col-span-9 inline-block">

								<div class="border-b m-6 py-2">
									<div class="float-left">
										<div class="rounded-lg w-6 inline-block align-middle">
											<img :src="env.logo" class="w-8" @click="switchEnv(env)">
										</div>
										<div class="inline-block align-middle">
											<p class="text-xl">{{env.name}}</p>
										</div>
										<div class="inline-block align-middle ml-4 text-xs" v-if="env.name=='AlbiSmart'">
											<p class="uppercase border border-teal-300 rounded-full px-2"> Default</p>
										</div>
									</div>
									<div class="float-right text-sm text-indigo-800">
										<div class="inline-block px-3">
											<i class="fas fa-power-off"></i>
											Turn Off
										</div>
										<div class="inline-block px-3">
											<i class="fas fa-pencil-alt"></i>
											Edit
										</div>
									</div>
									<div class="clear-both"></div>
								</div>

								<div class="mx-4 px-4 pb-4">
									<div class="inline-block mr-12">
										<p class="uppercase text-gray-500 text-sm">Build</p>
										<p class="text-gray-700">de21eoj</p>
									</div>
									<div class="inline-block mx-12">
										<p class="uppercase text-gray-500 text-sm">Apps</p>
										<p class="text-gray-700">12</p>
									</div>
									<div class="inline-block mx-12">
										<p class="uppercase text-gray-500 text-sm">Backup</p>
										<p class="text-gray-700">de21eoj</p>
									</div>
								</div>

							</div>
							<div class="col-span-3 border-l">
								<div class="m-4 p-2 pb-4">
									<button class="border rounded-full px-4 py-1 text-indigo-800 float-left hover:bg-gray-300 transition ease-in-out duration-200">Promote to</button>
									<button class="px-4 py-1 float-right text-indigo-800">
										<i class="fas fa-ellipsis-h"></i>
									</button>
									<span class="clear-both block"></span>
								</div>
								<div class="mx-4 px-4 pb-4">
									<p class="uppercase text-xs text-gray-500">Last deployed</p>
									<p class="text-gray-700 text-xs">Wed, November 15th, 2019</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- End - Cloud environments -->

			<!-- Home environments -->
			<div class="col-span-12 border-2 p-2 border-gray-100 bg-gray-100 w-full">
				<span class="inline-block flex items-center">
					<i class="fas fa-home fa-lg text-gray-500 inline-block"></i>
					<p class="inline-block text-1xl ml-2 text-gray-500">Home environments</p>
				</span>
			</div>

			<div class="col-span-12 shadow-inner p-10 h-64">
				<div class="inline-block mr-8 cursor-pointer" v-for="(env, e) in environments" :key="e">
					<div class="inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" @click="switchEnv(env)">
						<div class="shadow rounded-lg p-2" :class="[{'rounded-b-none': showCardName===e}, {'shadow': showCardName===e}]" @mouseenter="showName(e)" @mouseleave="showCardName = false">
							<img :src="env.logo" class="w-24">
						</div>
						<div class="text-center bg-gray-300 rounded-lg rounded-t-none cardName opacity-0" :class="{'opacity-important': showCardName===e}">{{ env.name }}</div>
					</div>
				</div>
			</div>
			<!-- End - Home environments -->


		</div>

		<div class="fixed w-64 h-64 centered" v-if="switchEnvironment">

			<div style="position: absolute; top: 30%; left: 30%;">
				<p class="text-gray-500">Switching Environments</p>
			</div>

			<div class="blob-1">
				<div class="w-24 h-24 bg-gray-200 shadow-lg rounded-full p-4">
					<img :src="previousEnv.logo">
				</div>
			</div>
			<div class="blob-2">
				<div class="w-24 h-24 bg-gray-200 shadow-lg rounded-full p-4">
					<img :src="nextEnv.logo">
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	const axios = require('axios');
	export default {
		name: 'Portal',
		data() {
			return {
				showCardName: null,
				environments: [
				{name: 'AlbiSmart', logo: require('@/assets/images/asmlogo.png')},
				{name: 'ArtMotion', logo: require('@/assets/images/art-logo.png')},
				{name: 'DigitalOcean', logo: require('@/assets/images/digital-o.png')}
				],
				selectedEnv: null,
				switchEnvironment: false,
				activeEnv: {name: 'AlbiSmart', logo: require('@/assets/images/asmlogo.png')},
				previousEnv: null,
				nextEnv: null,
				editingEnvName: false,
				editOn: false,
				userData: null
			}
		},
		mounted() {
			let vm = this;
			axios.get("https://albismart.cloud/user", 
				{ headers: { "Accept": "application/json", "Authorization": "Bearer "+this.$store.state.token} 
			})
			  .then(function (response) {
			    // handle success
			    // console.log(response);
			    if(response.status==200) {
				    vm.userData = response.data;
			    }
			  })
			  .catch(function (error) {
			    // handle error
			    console.log(error);
			  });
		},
		methods: {
			showName(index) {
				this.showCardName = index;
			},
			switchEnv(index) {
				this.previousEnv = this.activeEnv;
				this.nextEnv = index;
				this.switchEnvironment = true;
				this.activeEnv = {};
				this.activeEnv = index;
				setTimeout(() => {
					this.switchEnvironment = false;
				}, 3500);
			},
			editEnvName() {
				this.editingEnvName = true;
				this.$nextTick(() => {
					this.$refs.input.focus();
				});
			},
			updateEnvName() {
				this.editingEnvName = false;
				this.editOn = false;
			}
		},
		computed: {
			retrieveToken() {
				return this.$store.state.token;
			}
		}
	}
</script>
<style scoped>

/* Vue Transition */

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
.opacity-important {
	opacity: 1!important;
	transition: all 0.5s;
}


/* Animation */

.centered{
	width:400px;
	height:400px;

	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	/*background:#000;*/
	/*filter: blur(10px) contrast(20);*/
}
.blob-1,.blob-2{
	width:70px;
	height:70px;
	position:absolute;
	/*background:#fff;*/
	border-radius:50%;
	top:50%;left:50%;
	transform:translate(-50%,-50%);
}
.blob-1{
	left:50%;
	animation:osc-l 2.5s ease;
}
.blob-2{
	left:50%;
	animation:osc-r 2.5s ease;
	/*background:#0ff;*/
}
@keyframes osc-l{
	0%{left:20%;}
	50%{left:50%;}
}
@keyframes osc-r{
	0%{left:80%;}
	50%{left:50%;}
}

</style>