<template>
    <div>
        <button type="button" class="bg-gray-200 active:bg-secondary focus:outline-none text-gray-600 py-2 px-4 rounded" :class="{'modal-open': isActive}" @click="toggleModal">
            Turn on
        </button>

        <!--Modal-->
        <transition name="fade">
            <div class="modal transition duration-500 fixed w-full h-full top-0 left-0 flex items-center justify-center" v-if="isActive">
                <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                    <div class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                        <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                        <span class="text-sm">(Esc)</span>
                    </div>

                    <!-- Add margin if you want to see some of the overlay behind the modal-->
                    <div class="modal-content py-4 text-left px-6">
                        <!--Title-->
                        <div class="flex justify-between items-center pb-3">
                            <p class="text-2xl font-bold text-gray-800">{{ modalTitle }}</p>
                            <div class="modal-close cursor-pointer z-50" @click="isActive = false">
                                <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                </svg>
                            </div>
                        </div>

                        <!--Body-->
                        <div class="h-32">
                            <p class="text-gray-600">{{ modalContent }}</p>
                        </div>
                        <!-- <p>...</p>
                        <p>...</p>
                        <p>...</p>
                        <p>...</p> -->

                        <!--Footer-->
                        <div class="flex justify-end pt-2">
                            <button class="transition duration-300 ease-in-out bg-transparent py-2 px-4 rounded-lg text-indigo-500 hover:bg-primary hover:text-white mr-2 focus:outline-none">{{ actionButton }}</button>
                            <button class="transition duration-300 ease-in-out bg-indigo-500 py-2 px-4 rounded-lg text-white hover:bg-indigo-400 focus:outline-none" @click="isActive = false">{{ closeButton }}</button>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        name: 'Modal',
        props: {
            modalTitle: {
                type: String,
                default: 'Modal Title (:modalTitle attr)'
            },
            modalContent: {
                type: String,
                default: 'Modal content can go here (:modalContent attr)'
            },
            actionButton: {
                type: String,
                default: 'Action'
            },
            closeButton: {
                type: String,
                default: 'Close'
            }
        },
        data() {
            return {
                isActive: false
            }
        },
        created() {
            const handleEscape = (e) => {
                if(e.key === 'Esc' || e.key === 'Escape') {
                    this.isActive = false;
                }
            }
            document.addEventListener('keydown', handleEscape)

            this.$once('hook:beforeDestroy', () => {
                document.removeEventListener('keydown', handleEscape)
            })
        },
        methods: {
            toggleModal() {
                this.isActive = !this.isActive;
            }
        }
    }
</script>
<style scoped>
    .modal {
      transition: opacity 0.5s ease;
    }
    .modal-active {
      overflow-x: hidden;
      overflow-y: visible !important;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
</style>